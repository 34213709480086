<template>
  <section class="bg_color">
    <h2>あの人の登録モーター</h2>
    <section class="inner">
      <div class="ribon_outline">
        <div class="ttl_ribon nominate">
          <p class="ribon_inner"><Today /></p>
        </div>
      </div>
      <h3 class="ttl_main"><LastRace /></h3>

      <!--owner-->
      <div class="box_lst nominate">
        <h4 class="ttl_lst">
          <p class="inner crown">あの人の登録モーター</p>
        </h4>
        <!-- <div class="box_lnk">
          <a href="" class="btn_prev nolink">前の10件</a>
          <p class="count">1ページ/10</p>
          <a href="" class="btn_next">次の10件</a>
        </div> -->
        <div class="box_lst_sbox">
          <p class="list_title">GAMAファン</p>
          <!-- <p class="comingsoon">
            <img src="@/assets/images/common/comingsoon.svg" alt="" />
          </p> -->
          <ul class="lst">
            <li class="l2" v-for="val in filterUser_9" :key="val">
              <div class="box_thum">
                <figure>
                  <img :src="val.icon ? val.icon : Icon[0].img" alt="" />
                </figure>
                <p class="name nick">{{ val.nickname }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">順位</p>
                <p class="txt rank">{{ val.rank }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">獲得モグー</p>
                <p class="txt point_all">{{ val.current_point }}</p>
              </div>
              <div class="box_dtl">
                <router-link
                  :to="{ name: 'NominateDtl', params: { id: val.id } }"
                  class="btn_dtl"
                  >詳細を見る</router-link
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="box_lst_sbox">
          <p class="list_title">GAMA専門家</p>
          <!-- <p class="comingsoon">
            <img src="@/assets/images/common/comingsoon.svg" alt="" />
          </p> -->
          <ul class="lst">
            <li class="l2" v-for="val in filterUser_8" :key="val">
              <div class="box_thum">
                <figure>
                  <img :src="val.icon ? val.icon : Icon[0].img" alt="" />
                </figure>
                <p class="name nick">{{ val.nickname }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">順位</p>
                <p class="txt rank">{{ val.rank }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">獲得モグー</p>
                <p class="txt point_all">{{ val.current_point }}</p>
              </div>
              <div class="box_dtl">
                <router-link
                  :to="{ name: 'NominateDtl', params: { id: val.id } }"
                  class="btn_dtl"
                  >詳細を見る</router-link
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Today from "@/components/Today.vue";
import LastRace from "@/components/LastRaceInfo.vue";
import { mapGetters } from "vuex";
export default {
  name: "NominateList",
  components: {
    Today,
    LastRace,
  },
  data: function () {
    return {};
  },
  beforeMount: function () {
    this.$store.dispatch("getRoyalUserList");
  },
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("getRoyalUserList");
    }, 15000);
  },
  computed: {
    ...mapGetters({
      Icon: "getIcon",
      user: "getRoyalUserList",
    }),
    filterUser_8: function () {
      if (this.user.length > 0) {
        return this.user.filter(function (user) {
          return user.user_type === "8";
        });
      } else {
        return false;
      }
    },
    filterUser_9: function () {
      if (this.user.length > 0) {
        return this.user.filter(function (user) {
          return user.user_type === "9";
        });
      } else {
        return false;
      }
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
